<template>
  <div>

    <div class="d-flex align-items-center mb-1">
      <h2 class="pl-1">Чат с оператором</h2>

      <div class="d-flex align-items-center ml-2">
        <b-form-checkbox
            class="custom-control-success"
            name="check-button"
            v-model="chatLinkStatus"
            @change="switchChatLink"
            switch
        >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon"/>
            </span>
          <span class="switch-icon-right">
              <feather-icon icon="XIcon"/>
            </span>
        </b-form-checkbox>

        <div>
          <span>Скрыть/Показать раздел</span>
        </div>
      </div>
    </div>

    <ValidationObserver ref="validation-observer">
      <div class="d-flex flex-wrap">

        <!--  CHAT LINK  -->
        <div class="col-12 card__box-shadow mb-2 d-flex flex-wrap">

          <!--   CHAT LINK   -->
          <b-col cols="12">
            <ValidationProvider :name="`Ссылка`" rules="required" v-slot="{errors}">
              <b-form-group
                  label="Ссылка"
                  label-for="link"
              >
                <b-form-input
                    v-model="chatLink"
                    id="link"
                    size="lg"
                    placeholder="Ссылка"
                />
              </b-form-group>
              <span class="validation__red">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-col>

        </div>

        <div class="w-100">
          <b-button
              class="float-right"
              variant="success"
              type="submit"
              @click="updateChatLink()"
          >
            Сохранить
          </b-button>
        </div>

      </div>
    </ValidationObserver>

  </div>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner,
  BCard,
  BFormTextarea,
} from 'bootstrap-vue'
import ModalButton from "@/views/ui/modals/ModalButton";
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "ChatLink",
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    ModalButton,
    ToastificationContent
  },
  directives: {
    Ripple
  },
  data() {
    return {
      chatLinkStatus: false,
      chatLink: '',
      position: null,
      isBusy: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'position',
          label: 'Позиция',
        },
        {
          key: 'title',
          label: 'Заголовок',
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      items: [],
      pagination: {
        current: 1,
        total: 5,
        per_page: 5
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        // const routeQueryPage = parseInt(query.page)
        this.pagination.current = query.page
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  async created() {
    await this.getChatLink()
  },

  computed: {
    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key,
          }))
    },
  },

  methods: {

    async switchChatLink() {
      await api.chatLink.updateChatLinkStatus()
          .then(() => {
          })
          .catch((error) => {
            console.error(error)
          })
    },

    showToast(variant, text, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: icon,
          variant,
        },
      })
    },

    replaceRouter(query) {
      this.$router.replace({query})
          .catch(() => {
          })
    },

    async getChatLink() {
      this.isBusy = true
      await api.chatLink.fetchChatLink()
          .then(res => {
            this.chatLink = res.data.chat_link
            this.chatLinkStatus = !!parseInt(res.data["chatWithOperator"])
          })
          .catch((error) => {
            console.error(error)
          })
    },

    async updateChatLink() {
      const isValid = await this.$refs['validation-observer'].validate()
      if (isValid) {
        const formData = new FormData()
        formData.append('chat_link', this.chatLink)

        api.chatLink.updateChatLink(formData)
            .then(res => {
              this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
            })
            .catch((error) => {
              console.error(error)
              this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
            })
      }
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>

[dir] .dropdown-item {
  padding: 0;
}

.validation__red {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 6px;
}

.crud__btn .btn {
  padding: 0.6rem;
}

</style>
